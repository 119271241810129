import React, { useEffect, useState } from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import queryString from 'query-string'
import { serviceAPI } from '../services/api'

import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const NotFound = (props) => {
  const classes = useStyles()
  const { location: { search } } = props
  const { value } = queryString.parse(search) || {}
  console.log("🚀 ~ NotFound ~ value", value)
  const [initialView, setInitialView] = useState('form')
  const [isLoading, setIsLoading] = useState(false)
  const [optionValue, setOptionValue] = React.useState('option1');

  const handleChange = (event) => {
    setOptionValue(event.target.value);
  };

  useEffect(() => {
    const validateIsAlreadyUnsubscribe = () => {
      const alreadyEmails = localStorage.getItem('unsubscribe')
      if (!alreadyEmails) return
      const parseResponse = JSON.parse(alreadyEmails)

      if (parseResponse && parseResponse.length) {
        const searchCurrentlyEmail = parseResponse.find(el => el === value)
        if (searchCurrentlyEmail)
          setInitialView('thankyoupage')
      }
    }

    if (initialView === 'form')
      validateIsAlreadyUnsubscribe()
  }, [])

  const getUnsubscribeStatus = async (email) => {
    try {
      await serviceAPI.getUnsubscribe(email, optionValue)
    } catch (error) {
      console.log("🚀 ~ getUnsubscribeStatus ~ error", error)
    }
  }

  const _handleSendSuggestions = async () => {
    try {
      setIsLoading(true)
      await getUnsubscribeStatus(value, optionValue)
      const currentlyEmails = localStorage.getItem('unsubscribe')
      if (!currentlyEmails)
        localStorage.setItem('unsubscribe', JSON.stringify([value]))
      else {
        let parseEmails = JSON.parse(currentlyEmails)
        parseEmails.push(value)
        localStorage.setItem('unsubscribe', JSON.stringify(parseEmails))
      }
      setIsLoading(false)
      setInitialView('thankyoupage')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {
        initialView === 'form' ? <div className={classes.rootContainer}>
          <Card className={classes.root}>
            <CardContent>
              <Typography className={classes.title} color="error" gutterBottom variant="h4">
                Proceso para eliminar subscripción
              </Typography>
              <br />

              <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2">
                Nuestro equipo recibirá tu solicitud y no recibirás más correos de este tipo en el futuro.
              </Typography>
              <br />
              <Typography className={classes.title} color="textPrimary" gutterBottom variant="body2">
                Queremos saber cuáles son los motivos para mejorar nuestro servicio.
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup aria-label="gender" name="gender1" value={optionValue} onChange={handleChange}>
                  <FormControlLabel value="option1" control={<Radio />} label="No quiero recibir más correos" />
                  <FormControlLabel value="option2" control={<Radio />} label="No quiero recibir más correos de este tipo" />
                  <FormControlLabel value="option3" control={<Radio />} label="El contenido no es de mi interés" />
                  <FormControlLabel value="option4" control={<Radio />} label="Estoy recibiendo muchos correos de este tipo" />
                </RadioGroup>
              </FormControl>
            </CardContent>
            <CardActions>
                <Button disabled={isLoading} size="small" color="primary" variant="contained" onClick={_handleSendSuggestions}>ENVIAR</Button>
            </CardActions>
          </Card>
        </div> : <div className={classes.container} >
          <div className={classes.image}>
            <img alt='user-img' src='https://unsubscribe.rockwellhub.com/images/subscripcion.png' />
          </div>
          <div className={classes.section}>
            <Typography variant='body3' >
              Has decidido cancelar tu subscripción a nuestro servicio de notificaciones.
            </Typography>
            <br />
            <Typography variant='body3' >
              Nuestro equipo ya ha recibido tu solicitud y no recibirás más actualizaciones en el futuro.
            </Typography>
          </div>
        </div>
      }
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    alignContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary[10],
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  image: {
    margin: theme.spacing(5),
    maxHeight: 277,
    maxWidth: 500
  },
  section: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    maxWidth: 500,
    textAlign: 'center'
  }
}), { name: 'NotFound' })

export default NotFound
