import { createTheme } from "@material-ui/core"

const theme =  {
  overrides: {
    MuiButton: {
      root: {
        fontWeight   : 400,
        textTransform: 'initial'
      }
    },
    MuiPaper: {
      rounded: {
        borderRadius: 8
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1.125rem'
      }
    },
    MuiTypography: {
      body1: {
        fontSize: '0.75rem'
      },
      h1: {
        fontSize  : '2.75rem',
        fontWeight: 'bold'
      },
      h2: {
        fontSize  : '2.25rem',
        fontWeight: 'bold'
      },
      h3: {
        fontSize  : '1.8125rem',
        fontWeight: 'bold'
      },
      h4: {
        fontSize  : '1.4375rem',
        fontWeight: 'bold'
      },
      h5: {
        fontSize  : '1.125rem',
        fontWeight: 'bold'
      },
      h6: {
        fontSize  : '0.875rem',
        fontWeight: 'bold'
      }
    }
  },
  palette: {
    primary  : {
      10          : '#EBF8FF',
      100         : '#BAE7FF',
      200         : '#91D5FF',
      300         : '#69C0FF',
      400         : '#40A9FF',
      50          : '#E6F7FF',
      500         : '#1890FF',
      600         : '#096DD9',
      700         : '#0050B3',
      800         : '#003A8C',
      900         : '#002766',
      A14         : '#1890FF14',
      contrastText: '#fff'
    },
    secondary: {
      10          : '#F2F4F7',
      100         : '#CCD4E0',
      200         : '#99A9C2',
      300         : '#667DA3',
      400         : '#335285',
      50          : '#E6E9F0',
      500         : '#002766',
      600         : '#00235C',
      700         : '#001F52',
      800         : '#001B47',
      900         : '#00173D',
      A14         : '#00276614',
      contrastText: '#fff',
      dark        : '#00235C',
      light       : '#335285',
      main        : '#002766'
    }
    
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  }
}

export const newTheme = createTheme(theme)