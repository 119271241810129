import { API_INSTANCE } from './datalayer'

class API {

  async getUnsubscribe(email, optionValue) {
    try {
      const response = await API_INSTANCE.get(`/api/v1/operations/unsubscribe/${email}/${optionValue}`)
      const {
        data
      } = response
      const {
        already,
        success
      } = data

      if (success)
        return {
          already
        }
      else
        return {
          already: false
        }
    } catch (error) {
      return {
        already: false
      }
    }
  }
}

const serviceAPI = new API();


export {
  serviceAPI
}