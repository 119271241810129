import { ThemeProvider } from '@material-ui/core'
import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import NotFound from '../components/NotFound'
import { newTheme } from '../utils/theme'

const Router = () => (
  <ThemeProvider theme={newTheme}> 
    <BrowserRouter>
      <Switch>
        <Route component={NotFound} path='/email' />
        <Redirect to='/email' />
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
)

export default Router
