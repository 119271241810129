import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <Routes />,
  document.getElementById('root')
)

reportWebVitals()
